import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
const Repertoire = lazy(() => import("containers/Repertoire"));
const Home = lazy(() => import("containers/Home"));
const Performances = lazy(() => import("containers/Performances"));
const Performance = lazy(() => import("containers/Performance"));
const Creators = lazy(() => import("containers/Creators"));
const Creator = lazy(() => import("containers/Creator"));
const Theater = lazy(() => import("containers/Theater"));
const News = lazy(() => import("containers/News"));
const New = lazy(() => import("containers/New"));
const Educations = lazy(() => import("containers/Educations"))
const Education = lazy(() => import("containers/Education"))

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/spektakliai">
      <Performances />
    </Route>
    <Route exact path="/spektakliai/:slug">
      <Performance />
    </Route>
    <Route exact path="/edukacijos">
      <Educations />
    </Route>
    <Route exact path="/edukacijos/:slug">
      <Education />
    </Route>
    <Route exact path="/kurejai">
      <Creators />
    </Route>
    <Route exact path="/kurejai/:slug">
      <Creator />
    </Route>
    <Route exact path="/repertuaras">
      <Repertoire />
    </Route>
    <Route exact path="/teatras">
      <Theater />
    </Route>
    <Route exact path="/naujienos">
      <News />
    </Route>
    <Route exact path="/naujienos/:slug">
      <New />
    </Route>
  </Switch>
);

export default Routes;
